import useMenuNavigation from '@/hooks/useNavigation';
import { classNames } from '@/lib/utils';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import {
	Bars3Icon,
	ChevronDownIcon,
	XMarkIcon,
} from '@heroicons/react/20/solid';
import Link from 'next/link';
import { Fragment, useState } from 'react';
import { ProfileSlideOver } from './HeaderProfile';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Logo } from './Logo';

export function HeaderMenuItem({ item, setMobileMenuOpen }) {
	if (!item.children || item.children.length === 0) return null;
	return (
		<Disclosure as="div" key={item.name} className="-mx-3">
			{({ open }) => (
				<>
					<Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
						{item.name}
						<ChevronDownIcon
							className={classNames(
								open ? 'rotate-180' : '',
								'h-5 w-5 flex-none'
							)}
							aria-hidden="true"
						/>
					</Disclosure.Button>
					<Disclosure.Panel className="mt-2 space-y-2">
						{item.children.map((subItem) => (
							<Disclosure.Button
								key={subItem.name}
								as={Link}
								onClick={() => setMobileMenuOpen(false)}
								href={subItem.href}
								className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
							>
								{subItem.name}
							</Disclosure.Button>
						))}
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}

export default function HeaderMobileMenu({ children, languageAlternates }) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const navigation = useMenuNavigation();
	return (
		<>
			<button
				className="inline-flex shrink-0 items-center justify-center rounded-md p-2 text-inherit lg:hidden"
				type="button"
				onClick={() => setMobileMenuOpen(true)}
			>
				{children ? (
					children
				) : (
					<>
						<span className="sr-only">Open main menu</span>
						<Bars3Icon className="h-6 w-6" aria-hidden="true" />
					</>
				)}
			</button>

			<Transition.Root show={mobileMenuOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={setMobileMenuOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-md">
										<div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
											<div className="px-4 sm:px-6">
												<div className="flex items-center justify-between py-3">
													<Dialog.Title className="flex items-center">
														{/* <Logo withLink className="h-4 w-auto" /> */}
														<div onClick={() => setMobileMenuOpen(false)}>
															<Logo
																className="h-5 w-auto"
																linkClassName=""
																withLink
															/>
														</div>
													</Dialog.Title>
													<div className="ml-3 flex h-7 items-center">
														<button
															type="button"
															className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															onClick={() => setMobileMenuOpen(false)}
														>
															<span className="sr-only">Close panel</span>
															<XMarkIcon
																className="h-6 w-6 text-gray-700"
																aria-hidden="true"
															/>
														</button>
													</div>
												</div>
											</div>
											<div className="relative mt-2 flex-1 px-4 sm:px-6">
												{/* Your content */}
												<div className="flow-root">
													<div className="-my-6 divide-y divide-gray-500/10 py-4">
														<div className="space-y-2 py-2">
															<HeaderMenuItem
																item={navigation.routes}
																setMobileMenuOpen={setMobileMenuOpen}
															/>
															<HeaderMenuItem
																item={navigation.companies}
																setMobileMenuOpen={setMobileMenuOpen}
															/>
															<HeaderMenuItem
																item={navigation.destinations}
																setMobileMenuOpen={setMobileMenuOpen}
															/>
															<HeaderMenuItem
																item={navigation.stations}
																setMobileMenuOpen={setMobileMenuOpen}
															/>
														</div>

														<div className="space-y-2 py-2">
															{navigation.company.children.map((item) => (
																<Link
																	onClick={() => setMobileMenuOpen(false)}
																	key={item.name}
																	href={item.href}
																	{...(item.href.includes('https')
																		? {
																				rel: 'noreferrer noopenner',
																				target: '_blank',
																		  }
																		: {})}
																	className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
																>
																	{item.name}
																</Link>
															))}
														</div>
														<div className="py-6">
															<ProfileSlideOver />
														</div>
														<div className="py-6">
															<LanguageSwitcher
																languageAlternates={languageAlternates}
																onSelect={() => setMobileMenuOpen(false)}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
