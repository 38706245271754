import { project } from '@/lib/project';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { GoogleAdUnit } from './GoogleAdUnit';

export function LoadingSpinner() {
	return (
		<svg
			className="h-5 w-5 shrink-0 animate-spin text-indigo-600"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle
				className="opacity-25"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			></circle>
			<path
				className="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			></path>
		</svg>
	);
}

export default function GoogleAdVignette() {
	const router = useRouter();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation('common');
	const defaultTimeToClose = 3;
	const maxTimeToClose = 10;

	useEffect(() => {
		const handleRouteChangeStart = (url, { shallow }) => {
			if (url.includes('-x-')) {
				setOpen(true);
				setTimeout(() => setOpen(false), maxTimeToClose * 1000);
			}
		};

		const handleRouteChangeComplete = (url, { shallow }) => {
			if (url.includes('-x-')) {
				setOpen(false);
			}
		};

		router.events.on('routeChangeStart', handleRouteChangeStart);
		router.events.on('routeChangeComplete', handleRouteChangeComplete);

		return () => {
			router.events.off('routeChangeStart');
			router.events.off('routeChangeComplete');
		};
	}, [router]);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-[2147483648]" onClose={() => {}}>
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-lg transition-opacity" />

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-0 flex max-w-full">
							<Dialog.Panel className="pointer-events-auto m-auto h-screen w-screen overflow-hidden md:h-auto md:max-w-md lg:max-w-4xl">
								<div className="h-full bg-white md:h-auto md:rounded-lg md:shadow-xl">
									<div className="p-4 lg:px-8">
										<div className="flex items-center justify-between">
											<Dialog.Title
												as="div"
												className="inline-flex items-center gap-x-2 truncate "
											>
												<LoadingSpinner />
												<h3 className="truncate text-sm font-semibold leading-tight text-gray-700">
													{t('searching_schedules')}
												</h3>
											</Dialog.Title>
											<div className="ml-3 flex h-7 shrink-0 items-center">
												<button
													type="button"
													className="relative text-gray-900 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-transparent"
													onClick={() => setOpen(false)}
												>
													<span className="absolute -inset-2.5" />
													<span className="sr-only">Close panel</span>
													<XMarkIcon
														className="h-6 w-6 lg:h-8 lg:w-8"
														aria-hidden="true"
													/>
												</button>
											</div>
										</div>
									</div>
									<div className="px-4 lg:p-8">
										<div className="mb-4 hidden bg-white text-center md:block">
											<p className="text-xs font-normal uppercase text-gray-600">
												{t('adSense.disclaimer_after')}
											</p>
										</div>
										<GoogleAdUnit
											containerClassName="flex-grow bg-white"
											slotId={project.adSense.landing.ad1}
											className="h-[600px] w-[300px] lg:h-[250px] lg:w-full"
										/>
									</div>
								</div>
							</Dialog.Panel>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
