import { project } from '@/lib/project';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

const _isDev = process.env.NODE_ENV === 'development';

const loadAds = () => {
	try {
		if (typeof window !== 'undefined') {
			(window.adsbygoogle = window.adsbygoogle || []).push({});
		}
	} catch (error) {
		console.log('adsense error', error.message);
	}
};

export function GoogleAdUnit({
	slotId,
	className,
	containerClassName,
	enableAdAnnouncement = false,
	fullWidth = project.adSense.fullWidth,
	adFormat = null,
}) {
	const { t } = useTranslation('common');

	useEffect(() => {
		loadAds();
	}, []);

	if (!project.adSense.enabled) {
		return null;
	}

	const containerClassNames = enableAdAnnouncement
		? "before:content[''] relative lg:my-16 my-12 before:absolute before:-top-7 before:block before:h-[1px] before:w-full before:bg-gray-200 after:absolute after:-bottom-9 after:block after:h-[1px] after:w-full after:bg-gray-200 after:content-['']"
		: '';

	const adUnitClassNames = enableAdAnnouncement
		? 'before:absolute before:whitespace-nowrap before:left-1/2 before:-top-9 before:z-10 before:block before:-translate-x-1/2 before:transform before:bg-white before:px-4 before:text-xs before:text-gray-500 before:content-[attr(before)]'
		: '';

	return (
		<div className={clsx(containerClassNames, containerClassName)}>
			<ins
				before={t('adSense.disclaimer_before')}
				className={clsx(
					'adsbygoogle relative mx-auto block',
					className,
					adUnitClassNames,
					_isDev && 'bg-slate-200'
				)}
				data-ad-client={`ca-${project.adSense.accountId}`}
				data-ad-slot={slotId}
				data-ad-format={adFormat}
				data-full-width-responsive={fullWidth ? 'true' : 'false'}
			/>
		</div>
	);
}
