import useMenuNavigation from '@/hooks/useNavigation';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import HeaderMobileMenu from './HeaderMobileMenu';
import { ProfileDropdown } from './HeaderProfile';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Logo } from './Logo';
import { StaticLogo } from './StaticLogos';

export default function Header(props) {
	const {
		enabledCompanyLogo,
		companyId,
		companyName,
		hideHeaderLogo,
		languageAlternates,
		className,
	} = props;
	const navigation = useMenuNavigation();

	const companyLogo = companyId && StaticLogo({ id: companyId });

	const popovers = [
		navigation.routes,
		navigation.companies,
		navigation.destinations,
		navigation.stations,
	].filter((item) => item.children && item.children.length > 0);
	// if (navigation.stations.children && navigation.stations.children.length > 0) {
	// 	popovers.push(navigation.stations);
	// }

	return (
		<header
			className={clsx(
				'relative border-b border-gray-200 bg-white md:fixed md:inset-x-0 md:top-0 md:z-40',
				className
			)}
		>
			<nav
				className="mx-auto flex max-w-7xl items-center justify-between px-4 py-2 md:py-3.5 lg:px-8"
				aria-label="Global"
			>
				{enabledCompanyLogo && (
					<div className="md:hidden">
						{companyLogo ? (
							<Image
								priority
								src={companyLogo}
								alt={companyId}
								title={companyId}
								className="h-6 w-auto"
							/>
						) : (
							<span className="text-xl font-bold leading-none tracking-tight text-gray-900">
								{companyName}
							</span>
						)}
					</div>
				)}
				<div
					className={clsx(
						hideHeaderLogo && 'invisible',
						'md:visible md:flex-1'
					)}
				>
					<Logo className="h-5 w-auto lg:h-6" linkClassName="block" />
				</div>

				<Popover.Group className="hidden md:flex md:gap-x-2 xl:gap-x-8">
					{popovers.map((item) => {
						return (
							<Popover key={item.name} className="relative">
								{({ open }) => (
									<>
										<Popover.Button className="flex items-center gap-x-1 rounded-md px-4 text-sm font-semibold leading-6 text-gray-900">
											{item.name}
											<ChevronDownIcon
												className="h-5 w-5 flex-none text-gray-400"
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel className="absolute inset-x-0 top-full z-10 mt-3 w-max rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
												{({ close }) =>
													item?.children?.map((child) => (
														<Link
															onClick={() => close()}
															key={child.name}
															href={child.href}
															className="block cursor-pointer whitespace-nowrap rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-indigo-50"
														>
															{child.name}
														</Link>
													))
												}
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover>
						);
					})}
				</Popover.Group>

				<div className="flex flex-1 items-center justify-end gap-x-2">
					<LanguageSwitcher languageAlternates={languageAlternates} />
					<div className="hidden md:block">
						<ProfileDropdown />
					</div>
					<div className="-mr-2 flex md:hidden">
						<HeaderMobileMenu languageAlternates={languageAlternates} />
					</div>
				</div>
			</nav>
		</header>
	);
}
